.metrics {
    margin: 10px;
}

.metric {
    justify-content: space-around;
}

.metric span {
    text-align: center;
}

.city-header {
    display: flex;
    align-items: center;
}

.city-name {
    width: 100%;
    text-align: center;
    flex-grow: 1;
}

.toggle-favorite {
    cursor: pointer;
}

@media (max-width: 767px) {
    .toggle-favorite {
        font-size: 2em;
    }
}

.level-diff {
    text-align: center;
}

.level-diff span {
    margin-left: 4px;
    font-size: 0.8em;
}
