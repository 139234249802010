.top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.top-bar svg {
    cursor: pointer;
}

.last-update {
    width: 15em;
    margin-bottom: 0 !important;
}

@media (max-width: 767px) {
    .last-update {
        width: auto !important;
    }
}

.spinner {
    animation: spin 1 0.5s linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.city-setting {
    display: flex;
    justify-content: space-between;
    margin: 4px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .2s;
    transition: .2s;
}

input:checked+.slider {
    background-color: hsl(171, 100%, 41%);
}

input:focus+.slider {
    box-shadow: 0 0 1px hsl(171, 100%, 41%);
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
